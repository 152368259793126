import {util} from '../util.js';
import {AbstractComponent} from '../components/abstract-component.js';
import{ Otp } from '../components/opt.js';
import {getOtp} from '../data.js';

export class OtpController extends AbstractComponent {
  _container: Element;
  _otp: Otp;

  constructor(container: Element) {
    super();
    this._container = container;
    this._otp = new Otp(getOtp());
  }

  async init() {
    await this._otp._getOtpData();
    util.render(this._container, this._otp.getElement(), util.position.AFTERBEGIN);

    const paymentInfo: HTMLElement | null = document.getElementById('payment-info');
    if(paymentInfo) this._otp._initPaymentInfo(paymentInfo);

    this._otp.startCountDown();

    const resend = document.getElementById('resend');
    const cancelLink = document.getElementById('cancel-link');
    const codeForm = document.getElementById('otp-form');
    const codeInput = document.getElementById('code-input');


    if(resend) {
      resend.addEventListener('click', (e) => {
        this._otp._resendCode(e);
      });
    }

    if(cancelLink) {
      cancelLink.addEventListener('click', (e) => {
        this._otp._cancel(e);
      });
    }

    if(codeForm) {
       codeForm.addEventListener('submit', (e) => {
        e.preventDefault();
      });
    }

    if (codeInput) {
      //@ts-ignore
      codeInput.addEventListener('input', (e: Event & { target: HTMLInputElement }) => {
        this._otp._inputWatcher(e);
      });

      codeInput.addEventListener('keypress',  (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          this._otp._enterWatcher(e);
        }
      });
    }
  }
}
