
      if (!window.mainIsReady) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (queryString && urlParams) {
          const params = urlParams.get('q');
          const decoded = atob(params);
          const data = JSON.parse(decoded);
          window.location.href = data.returnUrl;
        } else {
          window.location.href = '/error';
        }
      }
    