import { AbstractComponent } from "./abstract-component.ts";
import errorHandler from "../helpers/error-handler.ts";
import { AccessControlServer } from "../utils/acs.ts";
import { TdsClient } from "../utils/tdss.ts";
import { ClientInfo } from "../utils/clientInfo.ts";
import { XhrClient } from "../utils/xhr-client.ts";
import { ThreeDSClientAuthResponse, ThreeDsData } from "../types.ts";
import * as Sentry from "@sentry/browser";

export class ThreeDs extends AbstractComponent {
    _tdsData: ThreeDsData | null;

    constructor() {
        super();

        this._tdsData = null;
        this._getTdsData();
        this._init();
    }

    getTemplate(): string {
        return `<section class="threeds">
        <span class="threeds__preloader"></span>
    </section>
  `;
    }

    _getTdsData() {
        const urlParams = new URLSearchParams(window.location.search);
        const tdsParams = urlParams.get("q");
        if (tdsParams) {
            const decoded: string = atob(tdsParams as string);
            this._tdsData = JSON.parse(decoded);
        } else {
            errorHandler(this._tdsData, "no query params");
        }
    }

    _init() {
        const clientInfo = new ClientInfo();
        Sentry.setUser({
            id: `${clientInfo.fingerprint}`,
        });
        Sentry.setContext("tdsData", this._tdsData);
        if (this._tdsData) {
            const acs = new AccessControlServer(this._tdsData.tds_method_url, this._tdsData.tds_method_data);
            try {
                if (this._tdsData?.send_client_info) {
                    const tdsClient = new TdsClient(this._tdsData.tds_client_info_url);
                    tdsClient.sendClientInfo(this._tdsData.tds_xid, clientInfo);
                }
                // @ts-ignore
                acs.sendFingerprint();
            } catch (e) {
                window.location.href = this._tdsData?.return_url || "/";
            }
            setTimeout(() => {
                new XhrClient(this._tdsData?.tds_client_auth_url || "", 60000)
                    .post<ThreeDSClientAuthResponse>(
                        "",
                        {
                            orderId: this._tdsData?.order_id,
                            threeDSServerTransID: this._tdsData?.tds_xid,
                            clientInfo: JSON.stringify(clientInfo),
                            csrfToken: this._tdsData?.csrf_token,
                        },
                        {},
                        "application/x-www-form-urlencoded"
                    )
                    .then((response) => {
                        if (response.errorCode !== 0) {
                            window.location.href = this._tdsData?.return_url || "/";
                        } else if (response.flowType.toLowerCase() === "frictionless") {
                            window.location.href = response.returnUrl || this._tdsData?.return_url || "/";
                        } else {
                            acs.challenge(response.acsUrl, response.creq, response.threeDSSessionData);
                        }
                    })
                    .catch((err) => {
                        errorHandler(this._tdsData, err);
                    });
            }, 2500);
        }
    }
}
