import { XhrClient, Headers } from "./xhr-client";
import { ClientInfo } from "./clientInfo";

export class TdsClient {
    client: XhrClient;

    constructor(baseUrl: string, timeout: number = 25000, headers: Headers = {}) {
        this.client = new XhrClient(baseUrl, timeout, headers);
    }

    async sendClientInfo(threeDSServerTransID: string, clientInfo: ClientInfo) {
        console.debug("[ThreeDSServer.sendClientInfo] sent");
        const searchParams = {
            threeDSServerTransID,
            clientInfo: JSON.stringify(clientInfo),
        };
        await this.client.post("", searchParams, {}, "application/x-www-form-urlencoded");
        // @ts-ignore
        console.debug("[ThreeDSServer.sendClientInfo] done");
    }
}
