export class AccessControlServer {
    threeDSMethodURL: string;
    threeDSMethodData: string;
    timeout: number;

    constructor(threeDSMethodURL: string, threeDSMethodData: string, timeout: number = 60 * 1000) {
        this.threeDSMethodURL = threeDSMethodURL;
        this.threeDSMethodData = threeDSMethodData;
        this.timeout = timeout;
    }

    getApp(): HTMLDivElement {
        const app = document.querySelector<HTMLDivElement>("#acs-frame");
        if (app === null) {
            throw new Error("[AccessControlServer.challenge] not found app element");
        }
        app.innerHTML = "";
        return app;
    }

    appendInput(form: HTMLFormElement, name: string, value: string): HTMLInputElement {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        form.append(input);
        return input;
    }

    appendChallengeForm(
        element: HTMLDivElement,
        acsUrl: string,
        creq: string,
        threeDSSessionData: string
    ): HTMLFormElement {
        const form = document.createElement("form");
        form.id = "challenge_form";
        form.method = "POST";
        form.action = acsUrl;
        this.appendInput(form, "creq", creq);
        this.appendInput(form, "threeDSSessionData", threeDSSessionData);
        element.append(form);
        return form;
    }

    appendFingerprintForm(frame: HTMLIFrameElement): HTMLFormElement {
        const form = document.createElement("form");
        form.id = "fingerprint_form";
        form.method = "POST";
        form.action = this.threeDSMethodURL;
        this.appendInput(form, "threeDSMethodData", this.threeDSMethodData);
        form.target = frame.name;
        frame.append(form);
        return form;
    }

    appendFrame(element: HTMLDivElement): HTMLIFrameElement {
        const frame = document.createElement("iframe");
        frame.name = "fingerprint_frame";
        frame.style.display = "none";
        element.append(frame);
        return frame;
    }

    challenge(acsUrl: string, creq: string, threeDSSessionData: string): void {
        console.debug("[AccessControlServer.challenge] sent");
        const app = this.getApp();
        const form = this.appendChallengeForm(app, acsUrl, creq, threeDSSessionData);
        form.submit();
        console.debug("[AccessControlServer.challenge] done");
    }

    sendFingerprint() {
        if (this.threeDSMethodURL === "" || this.threeDSMethodData === "") {
            return;
        }
        console.debug("[AccessControlServer.sendFingerprint] sent");
        const app = this.getApp();
        const frame = this.appendFrame(app);
        const form = this.appendFingerprintForm(frame);
        form.submit();
    }
}
