export const util = {
  position: {
    AFTERBEGIN: `afterbegin`,
    BEFOREEND: `beforeend`,
  },
  createElement(template: string) {
    const newElement = document.createElement(`div`);
    newElement.innerHTML = template;
    return newElement;
  },
  render(container: Element, element: ChildNode, place: string) {
    switch (place) {
      case this.position.AFTERBEGIN:
        container.prepend(element);
        break;
      case this.position.BEFOREEND:
        container.append(element);
        break;
    }
  },
};
