import { AbstractComponent } from './abstract-component.js';
import type { PaymentInfoData } from '../types.js';

type PaymentInfoType = {
    title: string;
    value: string;
    hidden?: boolean;
    type?: string
}

export class PaymentInfo extends AbstractComponent {

    _data: PaymentInfoType[];
    _cardType: string;

    constructor({merchantName, operationAmount, operationTime, cardNumber, phoneNumber, cardType} :PaymentInfoData) {
        super();

        this._cardType = cardType;

        this._data = [
            {
                title: "Магазин",
                value: merchantName,
                hidden: true
            },
            {
                title: "Сумма операции",
                value: operationAmount,
            },
            {
                title: "Время операции",
                value: operationTime,
                hidden: true
            },
            {
                title: "Номер карты",
                value: cardNumber,
                type: 'card',
            },
            {
                title: "Код отправлен на номер",
                value: phoneNumber,
                hidden: true
            }
          ];
    }

    getTemplate() {
        return `
        <div class="payment-info">
        ${Array.from(this._data).map((it) => {
            if(it.type === 'card') {
                let card = ``;
                if (this._cardType) card = `<span class="payment-info__card payment-info__card--${this._cardType.toLowerCase()}"></span>`
                it.value = `${card}<span class="payment-info__dots">••</span>${it.value.slice(12)}`
            }
            if(!it.hidden) return `<div class="payment-info__item">
        <dt class="payment-info__title">${it.title}</dt>
        <dd class="payment-info__content">${it.value}</dd>
    </div>`}).join(``)}
      </div>
  `;
    }
}
