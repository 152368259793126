import {util} from '../util.js';

export class AbstractComponent {
  _element: null | ChildNode;

  constructor() {

    if (new.target === AbstractComponent) {
      throw new Error(`Can't instantiate AbstractComponent, only concrete one.`);
    }

    this._element = null;
  }

  getElement(): ChildNode {
    if (!this._element) {
      this._element = util.createElement(this.getTemplate());
    }

    return this._element;
  }

  getTemplate(): string {
    return `Abstract method not implemented: getTemplate`;
  }
}
