import { ClientJS } from "clientjs";

export class ClientInfo {
  userAgent: string;
  colorDepth: string;
  screenHeight: number;
  screenWidth: number;
  javaEnabled: boolean;
  browserLanguage: string;
  browserTimeZone: string;
  browserTimeZoneOffset: number;
  javascriptEnabled: boolean;
  fingerprint: number;
  OS: string;
  OSVersion: string;
  device: string;
  deviceType: string;
  isMobile: boolean;
  screenPrint: string;
  plugins: string;

  constructor() {
    const client = new ClientJS();
    const screen = client.getCurrentResolution().split("x");
    this.userAgent = client.getUserAgent();
    this.colorDepth = client.getColorDepth();
    this.screenHeight = Number(screen[1] ? screen[1] : "0");
    this.screenWidth = Number(screen[0] ? screen[0] : "0");
    this.javaEnabled = client.isJava();
    this.browserLanguage = client.getLanguage();
    this.browserTimeZoneOffset = new Date().getTimezoneOffset();
    this.javascriptEnabled = true;
    this.fingerprint = client.getFingerprint();
    this.OS = client.getOS();
    this.OSVersion = client.getOSVersion();
    this.device = client.getDevice();
    this.deviceType = client.getDeviceType();
    this.isMobile = client.isMobile();
    this.screenPrint = client.getScreenPrint();
    this.plugins = client.getPlugins();
    this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
