import { AbstractComponent } from './abstract-component.js';

export class Error extends AbstractComponent {

    constructor() {
        super();
    }

    getTemplate(): string {
        return `
        <div class="wrap">
        <main>
            <div class="error">
                <div class="error__wrap">
                    <svg data-result="fail" width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" width="96" height="96" rx="48" fill="#F3F4F6" />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M33.6507 33.1508C34.4318 32.3698 35.6981 32.3698 36.4792 33.1508L63.3492 60.0209C64.1303 60.802 64.1303 62.0683 63.3492 62.8493C62.5682 63.6304 61.3018 63.6304 60.5208 62.8493L33.6507 35.9793C32.8697 35.1982 32.8697 33.9319 33.6507 33.1508Z"
                            fill="#EA1010"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M33.6507 62.8493C32.8697 62.0683 32.8697 60.802 33.6507 60.0209L60.5208 33.1508C61.3018 32.3698 62.5682 32.3698 63.3492 33.1508C64.1303 33.9319 64.1303 35.1982 63.3492 35.9793L36.4792 62.8493C35.6981 63.6304 34.4318 63.6304 33.6507 62.8493Z"
                            fill="#EA1010"
                        />
                    </svg>
                </div>
                <div class="error__content">
                    <div>Произошла ошибка оплаты</div>
                    <div>Вернитесь в магазин</div>
                </div>
            </div>
        </main>
    </div>
  `;
    }
}
