export type Headers = {
    [key: string]: string;
};

export class XhrClient {
    timeout: number;
    headers: Headers;

    constructor(private baseUrl: string, timeout: number = 60000, headers: Headers = {}) {
        this.timeout = timeout;
        this.headers = headers;
    }

    public get<T>(url: string, headers: Headers = {}): Promise<T> {
        const requestHeaders = { ...this.headers, ...headers };
        return this.sendRequest<T>("GET", url, undefined, requestHeaders);
    }

    public post<T>(
        url: string,
        data: any,
        headers: Headers = {},
        contentType: string = "application/json"
    ): Promise<T> {
        const requestHeaders = { ...this.headers, ...headers };
        const getBody = () => {
            switch (contentType) {
                case "application/x-www-form-urlencoded":
                    return this.toUrlEncoded(data);
                case "application/json":
                    return JSON.stringify(data);
                case "text/plain":
                    this.baseUrl = this.baseUrl + "?" + this.toUrlEncoded(data);
                    return "";
            }
        };
        return this.sendRequest<T>("POST", url, getBody(), requestHeaders, contentType);
    }

    private sendRequest<T>(
        method: string,
        url: string,
        body?: any,
        headers: Headers = {},
        contentType: string = "application/json"
    ): Promise<T> {
        const xhr = new XMLHttpRequest();

        return new Promise((resolve, reject) => {
            xhr.open(method, `${this.baseUrl}${url}`);
            xhr.timeout = this.timeout;

            Object.keys(headers).forEach((key) => {
                xhr.setRequestHeader(key, headers[key]);
            });

            xhr.setRequestHeader("Content-Type", contentType);

            xhr.onloadend = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    try {
                        resolve(JSON.parse(xhr.responseText));
                    } catch (error) {
                        // @ts-ignore
                        resolve({ errorCode: 0, errorDescription: "Success" });
                    }
                } else {
                    reject(new Error(`Request failed with status [${xhr.status}][${this.baseUrl}${url}]`));
                }
            };

            xhr.onerror = () => {
                reject(new Error(`Request failed due to network error [${this.baseUrl}${url}]`));
            };

            xhr.ontimeout = () => {
                reject(new Error(`Request timed out [${this.baseUrl}${url}]`));
            };

            xhr.send(body);
        });
    }

    private toUrlEncoded(data: any): string {
        return Object.keys(data)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
            .join("&");
    }
}
