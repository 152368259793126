import "./style.css";
import { util } from "./util.ts";
import * as Sentry from "@sentry/browser";
import { Error } from "./components/error.ts";
import { OtpController } from "./controllers/otp-controller.ts";
import { ThreeDs } from "./components/threeds.ts";
import { XhrClient } from "./utils/xhr-client";

const app = document.querySelector(`#app`);

const routes = {
    "/otp": downloadOtp,
    "/error": downloadError,
    "/tds": downloadTds,
};

function getRoute(): void {
    const path = window.location.pathname;
    //@ts-ignore
    if (routes[path]) {
        //@ts-ignore
        routes[path]();
    }
}

function downloadOtp() {
    if (app) {
        app.innerHTML = "";
        const otpController = new OtpController(app);
        otpController.init();
    }
}

function downloadTds() {
    const tds = new ThreeDs();
    if (app) util.render(app, tds.getElement(), util.position.AFTERBEGIN);
}

function downloadError() {
    const error = new Error();
    if (app) util.render(app, error.getElement(), util.position.AFTERBEGIN);
}

new XhrClient("", 5000)
    .get("/api/service/getDsn")
    .then((res: any) => {
        Sentry.init({
            dsn: res.dsn,
            tunnel: "/service/sentry/sendMessage",
            sendClientReports: false,
            tracesSampleRate: 0.0,
        });
    })
    .finally(() => {
        getRoute();
    });

//@ts-ignore
window["mainIsReady"] = true;
