import { AbstractComponent } from "./abstract-component.js";
import { PaymentInfo } from "./payment-info.js";
import errorHandler from "../helpers/error-handler.js";
import type { OtpData, Data } from "../types.js";
import { XhrClient } from "../utils/xhr-client.js";
import { util } from "../util.js";
import * as Sentry from "@sentry/browser";
import { ClientJS } from "clientjs";

export class Otp extends AbstractComponent {
    _title: string;
    _subtitle: string;
    _otpData: OtpData | null;
    _resendDelay: number;
    _canceled: boolean;
    _codeModel: string;
    _paymentInfoData: {
        phoneNumber: string;
        operationAmount: string;
        cardNumber: string;
        merchantName: string;
        operationTime: string;
        cardType: string;
    } | null;

    constructor({ title, subtitle }: { title: string; subtitle: string }) {
        super();
        this._title = title;
        this._subtitle = subtitle;
        this._otpData = null;
        this._resendDelay = 60;
        this._canceled = false;
        this._codeModel = "";
        this._paymentInfoData = null;

        const client = new ClientJS();
        Sentry.setUser({
            id: `${client.getFingerprint()}`,
        });
    }

    getTemplate(): string {
        return `<div class="wrap">
        <div class="header">
            <h1 class="header__title">
                ${this._title}
            </h1>
            <h2 class="header__subtitle">
                ${this._subtitle} ${this._paymentInfoData?.phoneNumber}
            </h2>
        </div>

        <div class="form-wrap">
            <form autocomplete="one-time-code" class="form" id="otp-form">
                <div class="form__core">
                    <input
                        autocomplete="one-time-code"
                        class="form__input"
                        minlength="${this._otpData?.code_length || 6}"
                        maxlength="${this._otpData?.code_length || 6}"
                        autofocus
                        inputmode="numeric"
                        id="code-input"
                    />

                    <div id="links-area" class="form__timer-wrap">
                        <div
                            href="#"
                        >
                            <span id="resend" class="form__resend">Новый код</span>
                            <span id="timer" class="form__timer"></span>
                        </div>
                    </div>
                </div>
                <small id="err-text" class="visually-hidden form__code-error">Код введен неверно</small>
            </form>

            <div id="payment-info"></div>
            <a
                id="cancel-link"
                href="#"
                class="cancel-btn"
            >Отменить</a>
        </div>
    </div>
  `;
    }
    startCountDown(time?: number) {
        this._hideResendLink();
        let timer = time ?? this._resendDelay;
        this._updateTimer(timer);
        this._showTimer();
        const countdown = setInterval(() => {
            if (timer > 1) {
                timer--;
                this._updateTimer(timer);
            } else {
                this._hideTimer();
                this._showResendLink();
                clearInterval(countdown);
            }
        }, 1000);
    }

    hide(element: HTMLElement) {
        element.classList.add(`visually-hidden`);
    }

    show(element: HTMLElement) {
        element.classList.remove(`visually-hidden`);
    }

    _cancel(e: Event) {
        e.preventDefault();
        this._canceled = true;
        this._sendOtp();
    }

    _blockLinksArea() {
        const linksArea = document.getElementById("links-area");
        if (linksArea) {
            linksArea.classList.add("pointer-events-none");
        }
    }

    _unblockLinksArea() {
        const linksArea = document.getElementById("links-area");
        if (linksArea) {
            linksArea.classList.remove("pointer-events-none");
        }
    }

    _showResendLink() {
        const element = document.getElementById("resend");
        if (element) {
            element.classList.remove("visually-hidden");
        }
    }

    _hideResendLink() {
        const element = document.getElementById("resend");
        if (element) {
            element.classList.add("visually-hidden");
        }
    }

    _showTimer() {
        const elementTimer = document.getElementById("timer");
        if (elementTimer) {
            elementTimer.classList.remove("visually-hidden");
        }
    }

    _hideTimer() {
        const elementTimer = document.getElementById("timer");
        if (elementTimer) {
            elementTimer.classList.add("visually-hidden");
        }
    }

    _updateTimer(time: number) {
        const temp = document.getElementById("timer");
        if (temp) {
            temp.innerText = `Новый код ${time} c`;
        }
    }

    _getOtpData() {
        const urlParams = new URLSearchParams(window.location.search);
        const otpParams = urlParams.get("q");
        if (otpParams) {
            const decoded: string = atob(otpParams as string);
            this._otpData = JSON.parse(decoded);
            Sentry.setContext("otpData", this._otpData);
            if (this._otpData) {
                this._fillPaymentInfo(this._otpData);
            }

            if (this._otpData?.resend_delay) {
                this._resendDelay = this._otpData.resend_delay;
            }
        } else {
            errorHandler(this._otpData, "no query params");
        }
    }

    _fillPaymentInfo(data: OtpData) {
        this._paymentInfoData = {
            merchantName: data?.merchant_name || "",
            operationAmount: data?.operation_amount || "",
            operationTime: data?.operation_time || "",
            cardNumber: data?.card_number || "",
            phoneNumber: data?.phone_number || "",
            cardType: data?.payment_system || ""
        };
    }

    _initPaymentInfo(container: HTMLElement) {
        if (this._paymentInfoData) {
            const elem = new PaymentInfo(this._paymentInfoData);
            util.render(container, elem.getElement(), util.position.AFTERBEGIN);
        }
    }

    _sendOtp() {
        if (this._otpData?.verify_url && this._otpData?.verify_url !== "") {
            this._blockLinksArea();
            const client = new XhrClient(this._otpData.verify_url, 5000);
            const input = document.getElementById("code-input") as HTMLInputElement;
            input.blur();
            input.disabled = true;
            input.readOnly = true;
            client
                .post(
                    "",
                    {
                        orderId: this._otpData?.order_id,
                        code: this._codeModel,
                        cancelled: this._canceled,
                    },
                    {},
                    "application/json"
                )
                // @ts-ignore
                .then((response: Data) => {
                    switch (response.errorCode) {
                        case 0: // Отправленный и введенный коды совпадают
                        case 173: // Пользователь нажал "Отменить"
                            this._redirect();
                            break;
                        case 172: // Отправленный и введенный коды не совпадают
                            const errText = document.getElementById("err-text");
                            if (input) {
                                input.disabled = false;
                                input.readOnly = false;
                                input.value = "";
                                input.focus();
                            }
                            if (errText) {
                                errText.textContent = "Код введен неверно";
                                this.show(errText);
                                if (input) input.classList.add("form__input--error");
                            }
                            this._unblockLinksArea();
                            break;
                        default:
                            this._unblockLinksArea();
                            throw new Error(response.errorMessage);
                    }
                })
                .catch((err) => {
                    errorHandler(this._otpData, err);
                });
            return;
        }
    }

    _redirect() {
        if (this._otpData?.return_url) {
            window.location.href = this._otpData.return_url;
        } else {
            window.location.href = "/error";
        }
    }

    _toggleElement(element: HTMLElement) {
        element.classList.toggle("visually-hidden");
    }

    _resendCode(event: Event) {
        event.preventDefault();

        if (this._otpData) {
            this.startCountDown(this._otpData?.resend_delay || 60);
            const client = new XhrClient(this._otpData.resend_url, 5000);
            client
                .post(
                    "",
                    {
                        // @ts-ignore
                        orderId: this._otpData.order_id,
                    },
                    {},
                    "application/json"
                )
                // @ts-ignore
                .then((response: Data) => {
                    if (response.errorCode !== 0) {
                        throw new Error(response.errorMessage);
                    }
                })
                .catch((err) => {
                    errorHandler(this._otpData, err);
                });
        }
    }

    _inputWatcher(event: Event & { target: HTMLInputElement }) {
        const { target } = event;
        const errText = document.getElementById("err-text");
        if (errText) this.hide(errText);
        target.classList.remove("form__input--error");
        target.value = target.value.replace(/\D/g, "");
        this._codeModel = target.value;
        if (this._otpData?.code_length && this._codeModel.length === this._otpData?.code_length) {
            this._sendOtp();
        }
    }

    _enterWatcher(event: any) {
        const { target } = event;
        this._codeModel = target.value;
        if (this._otpData?.code_length && this._codeModel.length === this._otpData?.code_length) {
            this._sendOtp();
        } else {
            const errText = document.getElementById("err-text");
            if (errText) {
                errText.textContent = `Длина кода должна содержать ${this._otpData?.code_length} символов`;
                this.show(errText);
            }
            target.classList.add("form__input--error");
        }
    }
}
